import React from 'react';

import { MenuLayout } from '../../components/menu-layout.component';
import { Footer } from '../../components/footer.component';


const faqsblocks = [
    {
        faqblock: 'PCR検査に関して',
        faqs: [
            { question: '症状があっても受けられますか？', answer: {__html: '以下の症状がある方は、郵送PCR検査を受けずにただちに指定医療機関と都道府県の相談窓口へお問い合わせいただき受診の相談をして下さい。それ以外の方で軽症・無症状の方は、どなたでも郵送PCR検査を受けていただけます。<br/><br/><ol><li>重篤な症状（呼吸が苦しい、息切れ、胸の痛みなど）がある方 </li><li>ご高齢（65歳以上）または基礎疾患（糖尿病、高血圧、がん、心疾患、呼吸器疾患、肝疾患、腎疾患、透析を行っているなど）がある方で新型コロナウイルス感染症を疑う症状（発熱、咳、倦怠感、息苦しさ、嗅覚異常など）がある方</li></ol>'} },
            { question: '年齢制限はありますか？', answer: {__html: '年齢制限はございません。'}},
            { question: '医療費控除の対象になりますか？', answer: {__html: 'なりません。医師の診察がなく自己の判断により受けたPCR検査は自由診療となります。そのため、医療費控除の要件には該当しないため控除の対象には当たりません。'}},
            { question: '申し込み完了後、キャンセルや返金は可能ですか？', answer: {__html: 'お申し込みのキャンセルや返金はできません。あらかじめご了承ください。'}},
            { question: '唾液採取用キットを紛失した場合、どうすれはいいですか？（破損含む）', answer: {__html: '再発送させていただきますが、別途3,000円（送料・税込）の料金をお支払いいただきます。お支払いに関しては、豊泉家PCR検査センターにお問い合わせください。'}},
            { question: '採取方法がわからないのですが？', answer: {__html: 'まずは、オンラインで<a class="t-p1 t-grey7 faq__answer__link" href="https://covid.sf-housenka.org/account/register/" target="_blank" rel="noopener noreferrer">検査キットの登録</a>をしてください。<br/>登録後、採取の方法や梱包の仕方を順にご案内しております。採取の方法は<a class="t-p1 t-grey7 faq__answer__link" href="/docs/howto.pdf" target="_blank" rel="noopener noreferrer">こちら</a>からもご確認いただけます。'}},
            { question: '唾液採取キットが届いたらすぐに使わないといけないですか？', answer: {__html: '唾液採取キットはご自宅で保管いただき、ご自身のタイミングで唾液の採取いただけます。使用期限は、お申し込み日から３ヶ月以内となっております。唾液採取後は、より正確に検査を行うために、採取当日に速やかに検査機関へご返送ください。'}},
            { question: '検査キット登録をする前に検体を返送してしまいました。', answer: {__html: 'ただちに豊泉家PCR検査センターまでお問い合わせください。検査を受けた方の情報とお持ちの検体番号をお知らせください。'}},
            { question: 'PCR検査精度はどのくらいですか？', answer: {__html: '検査室が実施する検査は、リアルタイムPCR法を用いてウイルス由来のRNAを分析し感染の有無を確認しています。抗原検査よりも検査精度は高いですが、100％の検査精度ではありません。また、ごく稀に偽陰性・偽陽性の可能性もあり得ます。'}},
            { question: '抗体検査はやっていますか？', answer: {__html: '実施していません。'}},
            { question: '定期的にスクリーニング検査を受けたいのですが？', answer: {__html: '毎月指定日に検査キットの配送をさせていただきます。'}},
            { question: '土日や祝日も発送や検査をしていますか？', answer: {__html: '日曜日と祝日は発送や検査は行なっておりません。検査キットの発送は、月～土曜日に行なっております。返送された検体のPCR検査は、月～金曜日に行っております。'}},
            { question: '検体をまとめて送付することは可能ですか？', answer: {__html: '可能です。団体でのお申し込みの場合は、返送用レターパックに複数個入れて、できるだけまとめて送付する様にお願いいたします。（厚さ3cm以下）'}},
            { question: '10個以上まとめて申し込みをして、送付先を数か所に送ってもらうことは可能でしょうか？', answer: {__html: '送付先は1か所でお願いいたします。'}},
            { question: '検体を採取したら、どのようにして送ったら良いのでしょうか？', answer: {__html: 'セルフPCR検査の<a class="t-p1 t-grey7 faq__answer__link" href="https://www.post.japanpost.jp/notification/covid-19/specimen.html" target="_blank" rel="noopener noreferrer">郵送方法の条件</a>を満たしておりますので、ポスト投函をしていただけます。<br/>条件1：検体が不活化していること<br/>条件2：検体に対し、医療機関などと同水準の厳重な三重包装した状態で安全に返送できること<br/>詳しくは、<a class="t-p1 t-grey7 faq__answer__link" href="/docs/howto.pdf" target="_blank" rel="noopener noreferrer">返送方法</a>についてをご覧ください。'}},
        ]
    },
    {
        faqblock: 'お届けに関して',
        faqs: [
            { question: '土日や祝日も発送や検査をしていますか？', answer: {__html: '検査キットの発送は、月～土曜日に行なっております。返送された検体のPCR検査は、月～金曜日に行っております。レターパック返送時の検査スケジュールは<a class="t-p1 t-grey7 faq__answer__link" href="/docs/pcr_letterpack.pdf" target="_blank" rel="noopener noreferrer">こちら</a>からご確認いただけます。'}},
            { question: '職場に濃厚接触者が出てすぐにでも検査したいのですが、最短でどの位で受けることができますか？', answer: {__html: '検査キットのお申し込みは即日可能です（検体キットを取りに来ていただく事が条件になります。大阪府豊中市中桜塚3-2-1まで）。その後、検体採取後、北大阪ほうせんか病院（大阪府茨木市室山1-2-1）までお持ち込みいただくことが最短での検査になります。<br/>ウェブでのお申し込みの場合、午前中のお申し込みは当日発送いたします。検査キット到着後、すぐに検体の採取・ポスト投函をしていただいた場合、検体を受領してから24時間以内に結果がわかります（クイックお申し込みの場合）。'}},
        ]
    },
    {
        faqblock: 'お申し込みに関して',
        faqs: [
            { question: '個人でのPCR検査申し込みは可能ですか？', answer: {__html: 'はい、PCR検査のお申し込みは、個人でもお申し込みいただけます。お申し込みをされる場合は、「個人の方」ページをご確認ください。'}},
            { question: 'インターネットの使い方がよく分からないのですが電話でも申し込みはできますか？', answer: {__html: 'はい、営業時間内（月～土曜日、9:00～18:00）にお電話でお申し込みいただけます。'}},
        ]
    },
    {
        faqblock: 'オンライン検査キット登録に関して',
        faqs: [
            { question: '検査キット登録とはなんですか？', answer: {__html: 'PCR検査を受けていただくためには、検査を受けていただく方の情報を1つずつ管理しなくてはなりません。豊泉家PCR検査センターでは、紙での申し込み書や同意書を省き、オンラインでご登録いただいております。スマートフォンやPCなどインターネットが繋がっている環境であれば、ご登録いただけます。検査キット登録方法に関してご質問などございましたら、お問い合わせください。'}},
            { question: 'ログイン用のパスワードを忘れました', answer: {__html: 'ログインページの「パスワードをお忘れですか？」からパスワードの再設定をしてください。'}},
            { question: 'ログイン用のメールアドレスを忘れました', answer: {__html: 'どうしてもログイン用のメールアドレスを思い出せない場合は、お問い合わせください。'}},
            { question: '現在使用できないメールアドレスが設定されている', answer: {__html: 'マイページにログインいただき、アカウント情報からメールアドレスの変更お願いいたします。'}},
            { question: '登録した個人情報を変更したいです', answer: {__html: 'マイページにログインいただき、アカウント情報から個人情報の変更お願いいたします。'}},
            { question: '登録したパスワードを変更したい', answer: {__html: 'マイページにログインいただき、設定のパスワード変更より手続きをお願いいたします。'}},
            { question: 'マイページで新規アカウントを登録する時、以前作成したアカウントと同じメールアドレスは使えますか？', answer: {__html: '使えません。アカウントの登録に使えるメールアドレスは、以前登録したことのないメールアドレスになります。'}},
        ]
    },
    {
        faqblock: '管理画面に関して',
        faqs: [
            { question: '企業担当者で結果を管理したいのですが？', answer: {__html: '管理画面上で登録状況～結果まで把握することが可能です。'}},
            { question: '従業員の登録はどのようにすればよいですか？', answer: {__html: '検査を受ける方々に、自分でマイページ登録していただくことができます。登録された情報は、管理画面に紐付けされます。<br/>個人での登録が難しい場合は、管理画面から管理者が個人を登録することも可能です。'}},
            { question: '従業員情報を一括インポートすることはできますか？', answer: {__html: 'はい、可能です。一括でインポートする場合は弊社で対応いたしますので、お問い合わせください。'}},
            { question: '間違えて違う人を登録をしてしまいました', answer: {__html: '登録が完了しても、検査が完了していなければ、上書き保存が可能です。マイページ、または管理画面より、正しい情報を入れ直してください。'}},
            { question: '複数人で管理できますか？', answer: {__html: '管理用のアカウントは1社につき1つとなり、ユーザーを増やすことはできません。1つのログイン情報を複数人で共有いただくことは可能です。'}},
        ]
    },
    {
        faqblock: '料金・お支払い方法に関して',
        faqs: [
            { question: '支払い方法を教えてください', answer: {__html: '■通常のPCR検査／陰性証明書<br/>　クレジットカード（VISA ／MASTER／JCB）／銀行振込／代引き<br/>■クイックPCR検査<br/>　クレジットカード（VISA ／MASTER／JCB）'}},
            { question: 'カード会社はどこの会社も対応していますか？', answer: {__html: 'VISA ／MASTER／JCBとなっております。'}},
            { question: '追加料金は発生しますか？', answer: {__html: '基本的には、追加料金は発生しません。価格内に送料は入っております。ただし、別途代引き手数料、振込手数料など場合によっては他社サービスの手数料がかかります。<br/>発生届対象者の検査結果が陽性の場合、連携医療機関（豊泉家クリニック桃山台：06-6873-6633）より電話診療させていただきます（発生届対象者外の方については、各都道府県のホームページを確認し陽性者登録を行ってください）。電話診療に際して、診療費用（保険診療）とシステム使用料1,100円（税込）が掛かる事に同意いただく必要があります（費用の請求書は、追って送付します）。'}},
        ]
    },
    {
        faqblock: '結果通知に関して',
        faqs: [
            { question: '検査結果は、いつ頃わかりますか？', answer: {__html: '検査場に検体が到着してから72時間以内に、検査結果通知をメールでお知らせいたします。また、マイページでも検査状況をご確認いただけます。<br/>検査スケジュールについては、<a class="t-p1 t-grey7 faq__answer__link" href="/docs/pcr_letterpack.pdf" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。'}},
            { question: '今日申し込みをした場合、通常のPCR検査とクイックPCR検査、どちらも最短でいつ結果が分かりますか？', answer: {__html: '検査場に検体が到着してから72時間以内です。クイック申し込の場合は検体を受領してから24時間以内です。'}},
            { question: '検査結果が陽性だった場合、どうすればいいですか？', answer: {__html: '発生届対象者の検査結果が陽性の場合、企業の担当者様に電話でご連絡いたします（発生届対象者については、各都道府県のホームページをご確認ください）。その後、ご本人様にも電話でお伝えし、さらにクリニックの医師がお電話で新型コロナウイルス感染症の症状などをご確認いたします。その後、迅速に保健所へ連絡しますので、保健所の指示を受けることができます。発生届対象外の方については、各都道府県のホームページの記載を確認し、陽性者登録を行なってください。'}},
            { question: '保健所から電話がこない、どうすればいいですか？', answer: {__html: '感染者数の状況によっては、保健所からの連絡に時間が要する場合があります。ご不安な場合は、最寄りの保健所に直接ご連絡ください。ただし、保健所から連絡がくるのは発生届対象者の場合のみです。'}},
            { question: '検査結果が陽性だった場合、家族が濃厚接触者になると思いますが、どうすればよいですか？', answer: {__html: '基本的には、濃厚接触者の認定は保健所によって判断されております。保健所の連絡または指示をお待ちいただくか、各都道府県の相談窓口にご連絡ください。それまでは、出来る限り他者との接触は避けてください。症状に心配がある場合は、ただちに指定医療機関と都道府県の相談窓口へお問い合わせいただき受診の相談をして下さい。'}},
        ]
    },
    {
        faqblock: '陰性証明書に関して',
        faqs: [
            { question: '陰性証明書は発行してもらえますか？', answer: {__html: '連携医療機関から発行します。オプションの陰性証明書を同時にお申し込みください。<br/>検体を発送する前に必ず、豊泉家PCR検査センター（0120-294-998）へご連絡ください。<br/>事前に連絡（検体番号と氏名）をいただけていない場合は、指定日に証明書を発行できないことがありますので、予めご了承ください。'}},
            { question: '検査結果通知書と陰性証明書の違いはなんですか？', answer: {__html: '『検査結果（陰性／陽性）通知書』<br/>連携医療機関が受け取った検体の結果を通知するものです。<br/><br/>『陰性証明書』<br/>医師が、採取いただいた検体が陰性であることを証明するものとなります。'}},
            { question: '陰性証明書を申し込んで陽性だった場合、陽性証明書はでますか？', answer: {__html: '陽性証明書の発行は行っておりません。陽性の方には結果通知書のみが発行され、陰性証明書でお支払いいただいた料金は返金いたします。'}},
            { question: '海外渡航用の陰性証明書は出してもらえるでしょうか。', answer: {__html: '国によって規定内容が異なりますので渡航先の大使館に必要事項をお問合せください。<br/>弊社の規定で作成した英語版の陰性証明書の発行はしております。<br/>（記載内容：氏名、パスポートナンバー、国籍、生年月日、性別、採取検体、検査法、結果、検体採取日、検査結果判定日、医療機関、医療機関住所、医師名）<br/>記載内容が充足している場合は、お問合せの上お申込みください。'} },
        ]
    },
];

export default function Faqs() {
    return (
        <MenuLayout disableSecureLayout={true} isAbs={true} className="homepage transparent">
            <section className="faqs">
                <div className="container">
                    <h1 className="t-heading1 faqs__heading">よくある質問</h1>
                    
                    {faqsblocks.map((block, i) => (
                        <div className="faqs-block" key={i}>
                            <h2 className="faqs-block__heading t-sans t-orange t-bold">{block.faqblock}</h2>
                            <div className="faqs-container">
                                {block.faqs.map((f, j) => (
                                    <div className="faq" key={j}>
                                        <p className="faq__question">{f.question}</p>
                                        <p className="faq__answer t-p1 t-grey7" dangerouslySetInnerHTML={f.answer} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <Footer />
        </MenuLayout>
    );
}